import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ViewModeService } from '@shared/services/view-mode.service';
import {
  DynamicFieldConfig,
  Field,
  FieldDto,
  McqSelectionModes,
  ModuleKeywords,
  QuestionFieldsDto,
  RequestHandlerOptions,
  getEnumOptions,
  isNullObj,
} from 'app/shared/classes';
import { BaseForm } from 'app/shared/classes/view/BaseForm';

@Component({
  selector: 'app-question-item-form',
  templateUrl: './question-item-form.component.html',
  styleUrls: ['./question-item-form.component.scss'],
})
export class QuestionItemFormComponent extends BaseForm<QuestionFieldsDto> implements OnInit {
  listOfQuestionType = getEnumOptions(Field.TypeEnum);
  listOfScoringType = getEnumOptions(QuestionFieldsDto.ScoringTypeEnum);

  @Input()
  itemId: string;

  @Input()
  searchQ: string = null;

  @Input()
  editMode: Boolean = false;

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Question);
  }

  ngOnInit(): void {
    if (this.editMode) {
      // this.questionsDataService.getByIdOrCode(this.itemId, this.apiOptions).subscribe((res) => {
      //     this.setData(res.data);
      // });
    } else {
      if (!!this.searchQ) {
        this.formGroup.patchValue({ question: this.searchQ });
      }
    }
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(this.formatGetData())
      : this.getChangedFormValues(this.formatGetData()).updateItems;
  }
  formatGetData() {
    let ret = this.formGroup.getRawValue();
    ret.fields = ret?.fields.map((x) => {
      return {
        name: x.name,
        key: x.key,
        type: x.type,
        options: x.options,
      };
    });
    return ret;
  }
  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
    this.onFieldChanges(data?.fields);
  }
  resetFormGroup(): void {
    this.formGroup.controls.fields.patchValue([]);
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      question: new FormControl(null, Validators.required),
      description: new FormControl(null),
      guide: new FormControl(null),
      scoringType: new FormControl(QuestionFieldsDto.ScoringTypeEnum.NotScored),
      // dynamicField: new FormGroup({
      //     name: new FormControl(null),
      //     label: new FormControl(null),
      //     type: new FormControl(null),
      //     // required: new FormControl(null),
      //     options: new FormControl(null),
      // }),
      fields: new FormControl([], [Validators.required, Validators.minLength(1)]),
      // questionType: new FormControl(null, Validators.required),
      // options: new FormControl(null),
    });
  }
  get dynamicFieldForm() {
    return this.formGroup.controls.dynamicField as FormGroup;
  }
  onFieldChanges(fields: FieldDto[]) {
    if (!isNullObj(fields)) {
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == McqSelectionModes.scored) {
          this.formGroup.controls.scoringType.patchValue(QuestionFieldsDto.ScoringTypeEnum.Scored);
          return;
        }
      }
    }
    this.formGroup.controls.scoringType.patchValue(QuestionFieldsDto.ScoringTypeEnum.NotScored);
  }
}
