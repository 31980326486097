<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.question.label' | translate: 'Question'"
        [placeholder]="moduleFieldString + '.question.placeholder' | translate: 'Enter Question'"
        [control]="formGroup?.controls?.question"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.scoringType.label' | translate: 'Scoring Type'"
        [placeholder]="moduleFieldString + '.scoringType.placeholder' | translate: 'Enter Scoring Type'"
        [translationKeyPrefix]="moduleFieldString + '.scoringType.enums'"
        [name]="'multi-dropdown'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="listOfScoringType"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.scoringType"
        [badgeView]="true"
        [viewMode]="'view'"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'answer_guide'"
        [label]="moduleFieldString + '.guide.label' | translate: 'Guide'"
        [placeholder]="moduleFieldString + '.guide.placeholder' | translate: 'Enter Guide'"
        [control]="formGroup?.controls?.guide"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <!-- <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Passing Score'"
        [placeholder]="'Passing Score...'"
        [name]="'MCQ_PASSING_SCORE'"
        [showButtons]="true"
        [control]="formGroup?.controls?.MCQ_PASSING_SCORE"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
        [min]="0"
      ></app-number-input>
    </div> -->
    <div class="col-12">
      <app-fields-table-selector
        [viewMode]="fieldViewMode != 'create' ? 'view' : fieldViewMode"
        [control]="formGroup?.controls.fields"
        [label]="moduleFieldString + '.fields.label' | translate: 'Question Fields'"
        [placeholder]="moduleFieldString + '.fields.placeholder' | translate: 'Enter Question Fields'"
        [helpText]="
          fieldViewMode == 'create'
            ? (moduleFieldString + '.fields.questionsAreNotChangeableAfterCreation'
              | translate: 'Questions are not changeable after creation')
            : null
        "
        (onChanges)="onFieldChanges($event)"
      ></app-fields-table-selector>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
